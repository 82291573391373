import './App.css';
import { Userroutes } from './components/Routes/Routes.component';

function App() {
  return (
    <Userroutes  />
  );
}

export default App;
