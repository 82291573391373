import { Route, Routes, Navigate } from "react-router-dom";
import { UrlsHelper } from "../../utils/url.util";
import { Login } from "../Login/Login.component";
import {Dashboard} from "../Dashboard/Dashboard.component";

export const Userroutes = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to={UrlsHelper.LANDINGPAGE} replace={true} />}
      />
      <Route path={UrlsHelper.LANDINGPAGE} element={<Login/>} />
      <Route path={UrlsHelper.DASHBOARD} element={<Dashboard/>} />
    </Routes>
  );
};
