//import { useState } from "react";
import NavbarComponent from "../Common/menu/Navbar.component";
import styles from "../Login/Login.module.css";
import { useNavigate } from "react-router-dom";
import { UrlsHelper } from "../../utils/url.util";

export const Dashboard = () => {
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [isOTPSend, setIsOTPSend] = useState(false);
  const navigate = useNavigate();
//   const setMobileNumberHandler = (e) => {
//     setMobileNumber(e.target.value);
//   };
//   const sendOTP = () => {
//     let verifyOtpApi = true;
//     if (verifyOtpApi) {
//       setIsOTPSend(true);
//     } else {
//       setIsOTPSend(false);
//     }
//   };
const login=1;
//   const cancelhandler = (e) => {
//     setIsOTPSend(false);
//   };

  const submitHandler = (e) => {
    navigate(UrlsHelper.DASHBOARD);
  };
  return (
    <div className="container-fluid">
      
      <div className="row">
        <NavbarComponent login={login}/>
      </div>
      <div className="row">
        
        <div className={` col-sm-12 col-md-12 col-lg-12`}>
            <div className={`${styles["donation"]}`}>
          {/* <div className={`${styles["headingver"]}`}>Online Services</div> */}
          <h2>Donation Receipt</h2>
          <div className={`${styles["tabs"]}`}>
            <button className={`btn btn-warning bg-orange`} id="regi_continue" type="button" > {" "}New Receipt Request{" "}</button>
            &nbsp;&nbsp;&nbsp;
            <button className={`btn btn-warning bg-orange`} id="regi_continue" type="button" > {" "}Receipt Request History{" "}</button>
          </div>
            <form method="post">
                
                <div className={`${styles[""]} row col-sm-12`}>
                    <div className={`${styles["bottom-border-thik"]} row`}>
                        <div className="col-md-3">
                            <label><strong>Full Name of Donor/Institution/ Organization</strong></label>
                            <input className={`form-control`} id="donarName"/>
                        </div>
                        <div className="col-md-3">
                            <label><strong> Purpose Of Donation</strong></label>
                            <select className={`form-control`} id="Purpose">
                                <option>Select Purpose Of Donation</option>
                                <option>Mandir Renovation/Repair</option>
                                <option>General Donation</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Donor PAN Number</strong></label>
                            <input className={`form-control`} id="donarPAN"/>
                        </div>
                    </div>
                    
                    <div className={`${styles["bottom-border-thik"]} row`}>
                        <div className="col-md-3">
                            <label><strong>Donation Date (DD/MM/YYYY)</strong></label>
                            <input type="date"  placeholder="dd-mm-yyyy" value="" min="1800-01-01" max="2030-12-31" className={`form-control`} id="date"/>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Donation Amount</strong></label>
                            <input type="text" className={`form-control`} id="donationAmount"/>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Trust Bank Name</strong></label>
                            <select className={`form-control`} id="bank">
                                <option>Select Trust Bank</option>
                                <option>State Bank of India</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Trust Account Number</strong></label>
                            <select className={`form-control`} id="account">
                            <option>Select Trust Account Number</option>
                                <option>91402321212212</option>
                            </select>
                        </div>
                        
                        <div className={`${styles["m-t-20"]} row`}>
                            <div className="col-md-3">
                                <label ><strong>Donation Mode</strong></label>
                                <select className={`form-control`} id="donationMode">
                                <option>Select Donation Mode</option>
                                    <option>Cash</option>
                                    <option>Cheque/DD</option>
                                    <option>UPI</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles["m-t-20"]} row`}>
                        <div className="col-md-6">
                            <label ><strong>Address Line 1</strong></label>
                            <input type="text" className={`form-control`} id="address1"/>
                        </div>
                        <div className="col-md-6">
                            <label ><strong>Address Line 2</strong></label>
                            <input type="text" className={`form-control`} id="address2"/>
                        </div>
                    </div>
                    <div className={`${styles["m-t-20"]} row`}>
                        <div className="col-md-3">
                            <label ><strong>State</strong></label>
                            <select className={`form-control`} id="state">
                            <option>Select State</option>
                                <option>Delhi</option>
                                <option>Uttar Pradesh</option>
                                <option>Haryana</option>
                                <option>Maharastra</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                                <label ><strong>City</strong></label>
                                <select className={`form-control`} id="city">
                                <option>Select City</option>
                                    <option>New Delhi</option>
                                    <option>Ayodhya</option>
                                    <option>Lucknow</option>
                                    <option>Mumbai</option>
                                </select>
                            </div>
                        <div className="col-md-3">
                            <label ><strong>Country</strong></label>
                            <input type="text" className={`form-control`} id="country"/>
                        </div>
                        <div className="col-md-3">
                            <label ><strong>Pincode</strong></label>
                            <input type="text" className={`form-control`} id="pincode"/>
                        </div>
                    </div>
                    <div className={`${styles["m-t-20"]} row`}>
                        <div className="col-md-3">
                            <label ><strong>Email ID</strong></label>
                            <input  className={`form-control`} id="emailid"/>
                        </div>
                        <div className="col-md-3">
                            <label ><strong>Mobile</strong></label>
                            <input type="text" className={`form-control`} id="mobile"/>
                        </div>
                    </div>
                    <div className={`${styles["m-t-20"]} row`}>
                        <div className="col-md-3">
                            <label><strong>4564d3</strong></label>
                            
                        </div>
                        <div className="col-md-3">
                            <input type="text" className={`form-control`} id="captch" placeholder="Enter Captcha Value"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8"></div>
                        <div className="col-md-2">
                            <button className={`${styles["btn-default"]} btn`}>Reset</button>
                            </div>
                        <div className="col-md-2">
                            <button className={`${styles["btn-cont"]} btn`} id="regi_continue"type="button" onClick={submitHandler}>{" "}Submit{" "}</button>
                        </div>
                    </div>
                    <div className={`${styles["m-t-20"]} row`}>
                        <div className="col-md-12">
                            <label><strong>Note : </strong></label>
                            <ul  className="notrc ng-star-inserted">
                                <li  className="text-note-dar"> Fields marked <sup  className="text-danger">*</sup> are mandatory. </li>
                                <li  className="text-note-dar"> To receive 80G certificate, PAN number entry is mandatory. </li>
                                <li  className="text-note-dar"> 50 % of Voluntary Contribution,for the purpose of renovation/repair of Mandir, to Shri Ram Janmbhoomi Teerth Kshetra is eligible for deduction under sec 80G(2)(b) of the Income Tax Act, 1961 (Vide Notification No. 24/2020/F.No.176/8/2017/ITA-I). </li>
                                <li  className="text-note-dar"> Cash Donation in excess of Rs. 2000 is not eligible for deduction under section 80G. </li></ul>
                            
                        </div>
                        
                    </div>       
                    
                </div>
              
            </form>
        </div>
          
        </div>
      </div>
    </div>
  );
};
