import { useState } from "react";
import NavbarComponent from "../Common/menu/Navbar.component";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { UrlsHelper } from "../../utils/url.util";

export const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isOTPSend, setIsOTPSend] = useState(false);
  const navigate = useNavigate();
  const setMobileNumberHandler = (e) => {
    setMobileNumber(e.target.value);
  };
  const sendOTP = () => {
    let verifyOtpApi = true;
    if (verifyOtpApi) {
      setIsOTPSend(true);
    } else {
      setIsOTPSend(false);
    }
  };

  const cancelhandler = (e) => {
    setIsOTPSend(false);
  };

  const submitHandler = (e) => {
    navigate(UrlsHelper.DASHBOARD);
  };
  return (
    <div className="container-fluid">
     
      <div className="row">
        <NavbarComponent />
      </div>
      <div className="row">
        
        <div className="col-lg-8"></div>
        <div className={`col-sm-12 col-md-12 col-lg-4`}>

          <div className={`${styles["verifyBox"]}`}>
            <div className={`${styles["headingver"]}`}>Login</div>
            {!isOTPSend && (
              <>
              <form>
                <div className={`${styles["boxCont"]} col-sm-12`}>
                  <div className="row">
                  <div className="col-sm-2 col-md-2 col-lg-2 col-2">
                  <input
                    className={`${styles["prefix"]} `}
                    value="+91"
                    readOnly
                  />
                  </div>
                  <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                  <input
                    className={`${styles["mob-re"]} form-control`}
                    required="required"
                    type="tel"
                    maxLength="10"
                    minLength="10"
                    pattern="^[0-9]*$"
                    onChange={setMobileNumberHandler}
                    value={mobileNumber}
                    
                  />
                  </div>
                  </div>
                </div>
                <div className={styles["otp-mob"]}>
                  {" "}
                  OTP will be sent to your Mobile Number
                </div>
                <div className="col-sm-12">
                  <button
                    className={`${styles["btn-cont"]}  btn`}
                    id="regi_continue"
                    type="button"
                    onClick={sendOTP}
                  >
                    {" "}
                    GET OTP{" "}
                  </button>
                </div>
                </form>
              </>
            )}
            {isOTPSend && (
              <>
                <div className={`${styles["boxCont"]} row col-sm-12`}>
                  Please enter OTP sent to the Mobile Number XXXXXX3347
                </div>
                <div className={`${styles["boxCont"]} row col-sm-12`}>
                  <input
                    className={`${styles["mob-re"]} form-control col-sm-10 col-md-8 col-lg-10 mob-re`}
                    maxLength="6"
                    minLength="4"
                    name="otp"
                    pattern="^[0-9]*$"
                    placeholder="enter OTP"
                    type="text"
                    required="required"
                  />
                </div>
                <div className={`${styles["boxCont"]} row col-sm-12`}>
                  <div className="col-6">
                    <button onClick={cancelhandler} className={`${styles["btn-default"]} btn`}>Cancel</button>
                  </div>
                  <div className="col-6">
                    <button onClick={submitHandler} className={`${styles["btn-cont"]} btn`}>Submit</button>
                  </div>
                </div>
              </>
            )}
          </div>

        </div>
      </div>
    </div>
  );
};
