import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import styles from "./Navbar.module.css";

function NavbarComponent(props) {
  return (
    <>
    <div className={`${styles["header-bg"]} row`}>
        <div className="col-sm-2"></div>
        <div className="col-sm-8">
          <img
            src="./asset/download.jpeg"
            className={styles["logo-head"]}
            alt="imh1"
          />
        </div>
        <div className="col-sm-2"></div>
      </div>

    <Navbar expand="lg" className={`${styles['navbar']} bg-body-tertiary`}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
        <Nav className="me-auto">
          <Nav.Link href="user" className={`${styles["nav-link"]}`}>Home</Nav.Link>
          <Nav.Link href="#" className={`${styles["bg-white"]} `}>Donation Receipt</Nav.Link>
          {props.login===1 && <Nav.Link href="user" className={` ${styles["nav-link"]} ${styles["float-right"]}`}>Logout</Nav.Link>}
        </Nav>
          {/* <Nav.Link href="user">Home</Nav.Link>
          <Nav.Link href="#link">Link</Nav.Link>
          <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </>
  );
}
export default NavbarComponent;
